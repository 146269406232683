import { ReactElement } from "react";
import { LeagueDownIcon } from "@/components/icons/LeagueDownIcon";
import { LeagueUpIcon } from "@/components/icons/LeagueUpIcon";
import { LeagueNonMoverIcon } from "@/components/icons/LeagueNonMoverIcon";

interface LeagueRankIconProps {
  rank: number;
  lastRank: number;
}

export const LeagueRankIcon = ({
  rank,
  lastRank,
}: LeagueRankIconProps): ReactElement => {
  if (rank < lastRank) {
    return <LeagueUpIcon testId={"leagueUpIcon"} size={14} />;
  }

  if (rank > lastRank) {
    return <LeagueDownIcon testId={"leagueDownIcon"} size={14} />;
  }

  return <LeagueNonMoverIcon testId={"leagueNonMoverIcon"} size={14} />;
};
