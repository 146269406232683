import { ReactElement } from "react";

interface Props {
  size?: number;
  testId?: string;
}

export const LeagueNonMoverIcon = ({
  size = 40,
  testId = "leagueNonMoverIcon",
}: Props): ReactElement => (
  <>
    <svg
      width={`${size}px`}
      height={`${size}px`}
      style={{ display: "block" }}
      data-testid={testId}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      version="1.1"
    >
      <defs>
        <clipPath id="clip1">
          <path d="M 5 5 L 9 5 L 9 9 L 5 9 Z M 5 5 " />
        </clipPath>
        <clipPath id="clip2">
          <path d="M 7 9 C 8.105469 9 9 8.105469 9 7 C 9 5.894531 8.105469 5 7 5 C 5.894531 5 5 5.894531 5 7 C 5 8.105469 5.894531 9 7 9 Z M 7 9 " />
        </clipPath>
      </defs>
      <g id="surface1">
        <g clipPath="url(#clip1)" clipRule="nonzero">
          <g clipPath="url(#clip2)" clipRule="evenodd">
            <rect
              x="0"
              y="0"
              width="14"
              height="14"
              style={{
                fill: "rgb(69.412231%,72.941589%,84.706116%)",
                fillOpacity: 1,
                stroke: "none",
              }}
            />
          </g>
        </g>
      </g>
    </svg>
  </>
);
