import { PredictionLiveDataMatchDetails } from "@/types/api/predictions";

const halfLength = 45;
const extraTimeHalfLength = 15;
const halfMap = new Map([
  [1, halfLength],
  [2, halfLength * 2],
  [3, halfLength * 2 + extraTimeHalfLength],
  [4, halfLength * 2 + extraTimeHalfLength * 2],
]);

export const formatMatchTime = ({
  matchTime = 0,
  periodId,
}: PredictionLiveDataMatchDetails): string => {
  const periodLimit = halfMap.get(periodId);

  return periodLimit && matchTime > periodLimit
    ? `${periodLimit}'+${matchTime - periodLimit}`
    : `${matchTime}'`;
};

type rankType = "winner" | "loser" | "playoff";

const rankIds: [string, rankType][] = [
  ["1xs4pt1q7317qb9ls31jycknh", "winner"],
  ["1xsyqokuvcgxzkrp50fdv6efh", "winner"],
  ["399v1vcmfxbbgqb66ol6j69uy", "winner"],
  ["1xtduv35tgzxclor6d6p71si5", "winner"],
  ["1xtcbz2o1ueks4g234xunq365", "winner"],
  ["1xt01bkzgznmchzglq5fmjr0t", "winner"],
  ["1xsyyi0hl5uvffcuxk7a95cwd", "winner"],
  ["1xt7su2ymy7ek83smwzb3trwt", "winner"],
  ["7thnd031f7lyhdqby21xlc60a", "winner"],
  ["1xt57wvl1c0kvy37flfoccxot", "winner"],
  ["3zpkd01syjlrzid4v1qsgq9gq", "winner"],
  ["1xsy07o6mnddbc0p9a02jhojx", "loser"],
  ["1xt2mn30d08xoq74o01x3o77x", "playoff"],
  ["1xsyirqbq5fo9pjcr3m10c0i5", "playoff"],
  ["1xt090bmlnryekoqtwhtvbhql", "playoff"],
  ["64dc9j63xdkc1b7g8t2k2vd3o", "playoff"],
  ["1xt8g8oekpjiqrjopvsa1axl9", "playoff"],
  ["5gbr06mzeb878pmit8wflyru2", "playoff"],
  ["1xtb8nd9ebckvtwamzgpjoq6l", "playoff"],
  ["1xt0goul7daky0ukw8rvu19ql", "playoff"],
];

const rankMap = new Map<string, rankType>(rankIds);

export const checkRankIdStatus = (
  rankId?: string
): "winner" | "loser" | "playoff" | "" => {
  if (!rankId) return "";
  const rankType = rankMap.get(rankId);
  return rankType ? rankType : "";
};
