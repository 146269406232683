import Image from "next/image";
import { ReactElement } from "react";
import {
  ContestantData,
  ContestantDataHeaders,
  LeagueTableChosenCompetition,
  LeagueTableChosenGroup,
  LeagueTableChosenTable,
  LeagueTableChosenTeam,
  StandingsResponse,
} from "@/types/api/standings";
import styles from "@/components/LeagueTable/LeagueTable.module.scss";
import CardWrapper from "@/components/CardWrapper/CardWrapper";
import { unixTimestampMsToDateMonthYear } from "@/lib/date";
import { LeagueRankIcon } from "@/components/LeagueRankIcon/LeagueRankIcon";
import { filterTableData } from "@/lib/filter";
import { checkRankIdStatus } from "@/lib/football";

export interface LeagueTableProps {
  data: StandingsResponse;
  tableType?: string;
  competition?: LeagueTableChosenCompetition;
  table?: LeagueTableChosenTable | null;
  groupTable?: LeagueTableChosenGroup | null;
  team?: LeagueTableChosenTeam | null;
}

type ContestantDataKeys = keyof ContestantDataHeaders;

const headerKeys: ContestantDataKeys[] = [
  "rank",
  "lastRank",
  "contestantShortName",
  "matchesPlayed",
  "matchesWon",
  "matchesDrawn",
  "matchesLost",
  "goaldifference",
  "points",
];

const headers = ["Pos", "", "Club", "P", "W", "D", "L", "GD", "PTS"];

const getTableCellData = (
  contestant: ContestantData,
  key: ContestantDataKeys
): ReactElement | string | number => {
  switch (key) {
    case "lastRank":
      return (
        <LeagueRankIcon rank={contestant.rank} lastRank={contestant.lastRank} />
      );
    case "contestantShortName":
      return (
        <div className={styles.teamDetails}>
          <Image
            src={`https://cdn.checkd-dev.com/football/teams/150x150/${contestant.contestantId}.png`}
            width={20}
            height={20}
            alt={"Team logo"}
          />
          <span>{contestant.contestantShortName}</span>
        </div>
      );
    default:
      return contestant[key];
  }
};

const getTableRows = (
  allContestants: ContestantData[],
  highlightedContestantId?: string
): ReactElement => {
  return (
    <>
      {allContestants.map(({ contestantId, rankId, ...rest }) => {
        const rankLabel = checkRankIdStatus(rankId);
        const isHighlighted = highlightedContestantId === contestantId;
        const rowClassName = `${styles.row} ${
          rankLabel !== "" ? styles[rankLabel] : ""
        } ${isHighlighted ? styles.highlightedRow : ""}`;

        return (
          <tr
            className={rowClassName}
            key={contestantId}
            data-testid={isHighlighted ? "highlighted" : ""}
          >
            {headerKeys.map((key) => {
              const dataCellClassName =
                key === "points" ||
                key === "contestantShortName" ||
                key === "rank"
                  ? `${styles.dataCell} ${styles.bold}`
                  : `${styles.dataCell}`;

              return (
                <td
                  className={dataCellClassName}
                  key={`${contestantId}-${key}`}
                >
                  {getTableCellData({ contestantId, rankId, ...rest }, key)}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export const LeagueTable = ({
  data,
  tableType,
  competition,
  table,
  groupTable,
  team,
}: LeagueTableProps): ReactElement => {
  const filterArguments = {
    tablesToFilter: data.data.tables,
    tableType: tableType,
    focusedTableId: table?.tableId,
    focusedGroupId: groupTable?.groupId,
    highlightedContestantId: team?.contestantId,
  };

  const filteredTables = filterTableData(filterArguments);

  return (
    <>
      {filteredTables &&
        filteredTables.map((leagueTable) =>
          // Get group table data
          leagueTable.table.map(({ groupName, ranking }) => (
            <CardWrapper
              listHeader={`${
                competition ? competition.name : data.data.meta.name
              } ${table ? ` - ${table.name} ` : ""} ${
                groupTable ? ` - ${groupTable.name} ` : ""
              }`}
              listImage={`https://cdn.fantasyiteam.com/bethub/competitions/150x150/${data.data.meta.id}.png`}
              competitionHeader={true}
              key={`${leagueTable.name}-${groupName}`}
            >
              <table className={styles.table}>
                <thead className={styles.header}>
                  <tr>
                    {headers.map((header) => (
                      <th className={styles.headerCell} key={header}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{getTableRows(ranking, team?.contestantId)}</tbody>
                <tfoot>
                  <tr
                    className={styles.footerRow}
                    data-testid="leagueTableFooter"
                  >
                    <td colSpan={headers.length}>
                      Last updated{" "}
                      {unixTimestampMsToDateMonthYear(Date.now()).replace(
                        /-/g,
                        "/"
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </CardWrapper>
          ))
        )}
    </>
  );
};
