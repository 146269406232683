import { ReactElement } from "react";

interface Props {
  size?: number;
  testId?: string;
}

export const LeagueDownIcon = ({
  size = 40,
  testId = "leagueDownIcon",
}: Props): ReactElement => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    style={{ display: "block" }}
    data-testid={testId}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    version="1.1"
  >
    <g id="surface1">
      <path
        style={{
          fill: "none",
          strokeWidth: 2,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          stroke: "rgb(90.588379%,12.548828%,20.783997%)",
          strokeOpacity: 1,
          strokeMiterlimit: 10,
        }}
        d="M 0 0 L 4 4 L 8 0 "
        transform="matrix(1,0,0,1,3,5)"
      />
    </g>
  </svg>
);
